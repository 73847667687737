<template>
  <b-overlay
    id="overlay-background"
    :show="loading"
    blur="10px"
    opacity="0.30"
    rounded="sm"
    variant="light"
  >
    <section class="invoice-preview-wrapper font-weight-bolder">
      <!--    Alert: No item found-->
      <b-alert
        variant="danger"
        :show="typeof Items === 'undefined'"
      >
        <h4 class="alert-heading">
          No invoice found with this invoice id
        </h4>
      </b-alert>

      <b-row
        :v-if="Items"
        class="invoice-preview"
      >
        <!-- Col: Left (Invoice Container) -->
        <b-col
          cols="12"
          xl="9"
          md="8"
        >
          <b-card
            no-body
            class="invoice-preview-card"
          >
            <!-- Header -->
            <b-card-body class="invoice-padding pb-0">
              <!-- Header: Left Content -->
              <b-row class="invoice-spacing">
                <b-col
                  cols="12"
                  xl="6"
                  class="p-0"
                >
                  <div class="logo-wrapper">

                    <!--<logo />-->
                    <b-img
                      style=" height: 200px; width: 200px"
                      :src="defaultCompanyLogo"
                      alt="Company Logo"
                    />
                    <div
                      class="invoice-title"
                      style=" font-size: 18px;"
                    >
                      Parallax Technologies Pvt Ltd
                    </div>
                    <div class="font-weight-light card-text mb-0">
                      125/2 3rd Lane, Nugegoda,<br>
                      Subadrarama Road,<br>
                      Nugegoda Western 10250 <br>
                      Sri Lanka
                    </div>
                  </div>
                </b-col>

                <!-- Col: Payment Details -->
                <b-col
                  xl="6"
                  cols="12"
                  class="p-0 mt-xl-0 mt-2 d-flex justify-content-xl-end"
                >
                  <!-- Header: Right Content -->
                  <div class="invoice-title-head">
                    <table>
                      <tbody>
                        <tr>
                          <td class="pr-1 text-right">
                            <span
                              class="invoice-title"
                              style=" font-size: 35px; margin-right: 12px;"
                            >
                              Invoice
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td class="pr-1 text-right">
                            <span class="font-weight-bolder pr-1">
                              {{ Items.inv_ref_no }}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td class=" pr-1 text-right">
                            <span class="font-weight-bolder pr-1">
                              Balance Due :  {{ Items.payment_status === 'due' ? Items.total_amount : (Items.payment_status === 'paid' ? 0 : '') }}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td class=" pr-1 text-right">
                            <span class="font-weight-bolder pr-1">
                              Grace Days :  {{ dateDifferenceinDays }}
                            </span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </b-col>
              </b-row>
            </b-card-body>

            <!-- Spacer -->
            <hr class="invoice-spacing">

            <!-- Invoice Client & Payment Details -->
            <b-card-body
              class="invoice-padding pb-0"
            >
              <b-row class="invoice-spacing">
                <b-col
                  cols="12"
                  xl="6"
                  class="p-0"
                >
                  <div class="font-weight-light">
                    Bill To :
                  </div>
                  <div class="mb-1">
                    {{ Items.tenant.business_name === null ? Items.tenant.name : Items.tenant.business_name }}
                  </div>
                  <div
                    class="mb-1"
                  >
                    Subject:
                  </div>
                  <div
                    class="mb-1"
                  >
                    Curfox Invoice
                  </div>
                </b-col>

                <!-- Col: Payment Details -->
                <b-col
                  xl="6"
                  cols="12"
                  class="p-0 mt-xl-0 mt-2 d-flex justify-content-xl-end"
                >
                  <div>
                    <table>
                      <tbody>
                        <tr>
                          <td class="pr-1">
                            <span class="font-weight-bolder">
                              Invoice No:
                            </span>
                          </td>
                          <td><span class="font-weight-light">{{ Items.inv_ref_no }}</span></td>
                        </tr>
                        <tr>
                          <td class="pr-1">
                            <span class="font-weight-bolder">
                              Terms:
                            </span>
                          </td>
                          <td><span class="font-weight-light">Custom</span></td>
                        </tr>
                        <tr>
                          <td class="pr-1">
                            <span class="font-weight-bolder">
                              Due Date:
                            </span>
                          </td>
                          <td><span class="font-weight-light">{{ Items.payment_due_date }}</span></td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </b-col>
              </b-row>
            </b-card-body>
            <!-- Invoice Description: Table -->
            <b-table-lite
              responsive
              :fields="invoiceTableStructure"
              :items="rows"
            />
            <template #cell(subscription_list.subscription.package.package_name)="data">
              {{ data.value }}
            </template>
            <!-- Invoice Description: Total -->
            <b-card-body class="invoice-padding pb-0">
              <b-row>
                <b-col
                  xl="11"
                  cols="12"
                  class="p-0 mt-xl-0 mt-2 d-flex justify-content-xl-end"
                >
                  <div class="mb-1 invoice-total">
                    <table class="invoice-total">
                      <tbody>
                        <tr>
                          <td class="pr-1 text-right">
                            Total Amount
                          </td>
                          <td><span class="font-weight-light">{{ Items.total_amount }}</span></td>
                        </tr>
                        <tr>
                          <td class="pr-1 text-right">
                            Billed Orders
                          </td>
                          <td><span class="font-weight-light">{{ Items.billed_order_count }}</span></td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </b-col>
              </b-row>
            </b-card-body>
          </b-card>
        </b-col>
        <!-- Right Col: Card -->
        <b-col
          cols="12"
          md="4"
          xl="3"
          class="invoice-actions"
        >
          <b-card>

            <!-- Button: Print -->
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              variant="outline-success"
              class="mb-75"
              block
              @click="printInvoice"
            >
              Print
            </b-button>

          </b-card>
        </b-col>
      </b-row>
    </section>
  </b-overlay>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BCardBody,
  BOverlay,
  BTableLite,
  VBToggle,
  BButton,
  BImg,
  BAlert,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { defaultCompanyLogo } from '@/constants/config'
import { RepositoryFactory } from '@/repository/RepositoryFactory'

const SubscriptionRepository = RepositoryFactory.get('subscription')

export default {
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BOverlay,
    BTableLite,
    BButton,
    BImg,
    BAlert,
  },
  data() {
    const invoiceTableStructure = [
      {
        key: 'package.package_name',
        label: 'Package Name',
        tdClass: 'text-left',
      },
      {
        key: 'package.order_limit',
        label: 'Order Limit',
        tdClass: 'text-left',
      },
      {
        key: 'start_date',
        label: 'Start Date',
        tdClass: 'text-left',
      },
      {
        key: 'end_date',
        label: 'End Date',
        tdClass: 'text-left',
      },
      {
        key: 'billed_amount',
        label: 'Total Amount',
        tdClass: 'text-left',
      },
    ]
    return {
      rows: [],
      fields: [],
      invoiceTableStructure,
      subscriptionId: null,
      defaultCompanyLogo,
      loading: false,
    }
  },
  async mounted() {
    this.subscriptionId = Number(this.$route.params.id)
    await this.fetchDataForInvoice()
  },
  methods: {
    async fetchDataForInvoice() {
      try {
        this.loading = true
        const { data } = (await SubscriptionRepository.getInvoiceData(this.subscriptionId)).data
        this.Items = data
        this.rows = data.subscriptions
        const currentDate = new Date()
        const paymentDueDate = new Date(data.payment_due_date)
        const dateDifferenceinMS = currentDate.getTime() - paymentDueDate.getTime()
        const dateDifferenceinDays = Math.ceil(dateDifferenceinMS / (1000 * 3600 * 24))
        this.dateDifferenceinDays = dateDifferenceinDays
      } catch (error) {
        this.convertAndNotifyError(error)
      }
      this.loading = false
    },
    printInvoice() {
      window.print()
    },
  },
}
</script>
<style lang="scss">
@media print {
  @page {
    size: A4
  }
  body {
    background-color: transparent !important;
  }
  * {
    -webkit-print-color-adjust: exact !important;
    color-adjust: exact !important;
  }
  nav.header-navbar {
    display: none;
  }
  .taxSection {
    display: contents;
  }
  .main-menu {
    display: none;
  }
  .header-navbar-shadow {
    display: none !important;
  }
  .content.app-content {
    margin-left: 0;
    padding-top: 2rem !important;
  }
  footer.footer {
    display: none;
  }
  .card {
    background-color: transparent;
    box-shadow: none;
  }
  .customizer-toggle {
    display: none !important;
  }
  // Invoice Specific Styles
  .invoice-preview-wrapper {
    .row.invoice-preview {
      .col-md-8 {
        max-width: 100%;
        flex-grow: 1;
      }
      .invoice-total{
        margin-left: 420px;
      }
      .invoice-title-head{
        margin-left: 765px;
      }

      .invoice-preview-card {
        .card-body:nth-of-type(2) {
          .row {
            > .col-12 {
              max-width: 50% !important;
            }

            .col-12:nth-child(2) {
              display: flex;
              align-items: flex-start;
              justify-content: flex-end;
              margin-top: 0 !important;
            }
          }
        }
      }
    }
  }
  .invoice-actions {
    display: none;
  }
}
</style>
